import moment from "moment";
import { marked } from "marked";
import { gfmHeadingId } from "marked-gfm-heading-id";
marked.use(gfmHeadingId());

export class Article {
  get title() {
    return this.fields.title;
  }
  get description() {
    return this.fields.description;
  }

  get description_short() {
    return `${this.description.substring(0, 100)}...`;
  }

  get top_image() {
    return {
      url: this.fields.topImage.fields.file.url + "?w=700&h=438&fit=fill",
      thumb: { url: this.fields.topImage.fields.file.url + "?fit=thumb" },
    };
  }

  get published_at() {
    return moment(this.fields.published_at).format("YYYY年MM月DD日");
  }

  get detailPath() {
    return `/articles/${this.fields.slug}`;
  }

  get author() {
    return {
      name: this.fields.author.fields.name,
      image: { icon: { url: this.fields.author.fields.image.fields.file.url } },
    };
  }

  get category() {
    return {
      name: this.fields.category.fields.name,
      description: this.fields.category.fields.description,
      slug: this.fields.category.fields.slug,
    };
  }

  get content() {
    return this.fields.content;
  }

  get parsed_content() {
    return marked(this.content);
  }

  get headings() {
    return marked
      .lexer(this.content)
      .filter((token) => token.type === "heading")
      .map((token) => {
        token["id"] = token.text
          .replace(/\s+/g, "-")
          .replace(/[・。!$%^&@#*()+|~=`{}[\]:";'<>?,./]/g, "")
          .toLowerCase();
        return token;
      });
  }

  private fields: {
    title: string;
    description: string;
    topImage: {
      fields: {
        file: { url: string };
      };
    };
    content_type: string;
    content: string;
    published_at: string;
    category: {
      fields: {
        name: string;
        description: string;
        slug: string;
      };
    };
    author: {
      fields: {
        name: string;
        image: {
          fields: { file: { url: string } };
        };
      };
    };
    slug: string;
  };
}
