import { defineStore } from "pinia";
import { plainToClass } from "class-transformer";
import { Article } from "@/models/article";
import { Pagination } from "@/models/pagination";
import { ArticleCategory } from "@/models/article_category";

export const useArticleStore = defineStore("article", {
  state: () => ({
    item: null,
    items: [],
    pagination: null,
    categories: [],
  }),
  getters: {
    title() {
      let text = `${this.placeTypeLabel}の検索結果一覧`;
      if (this.features.length == 1) {
        text = `${this.features[0].name}の${this.placeTypeLabel}一覧`;
      }
      return text;
    },
    description() {
      let prefix = "全国";
      if (this.features.length == 1) {
        prefix = this.features[0].name;
      }
      return `${prefix}の${this.placeTypeLabel}をご紹介。\
        ドッグフルは日本最大級のドッグラン検索サイト。愛犬と過ごせる${this.placeTypeLabel}を検索できます。\
        `.replace(/\s+/g, "");
    },
  },
  actions: {
    async fetchAll(params: any) {
      const { $contentfulClient } = useNuxtApp();

      this.pagination = new Pagination({
        per_page: params.per_page,
        current_page: params.page,
      });
      const queryParams = {
        content_type: "article",
        limit: params.per_page,
        order: "-fields.published_at",
      };
      if (params.category) {
        queryParams["fields.category.sys.contentType.sys.id"] = "category";
        queryParams["fields.category.fields.slug[match]"] = params.category;
      }
      if (params.page >= 2) {
        queryParams["skip"] = (params.page - 1) * params.per_page;
      }
      const entries = await $contentfulClient.getEntries(queryParams);

      if (entries.items) {
        this.pagination.total_count = entries.total;
        this.items = plainToClass(Article, entries.items);
      }
    },
    async fetch(slug: string) {
      const { $contentfulClient } = useNuxtApp();

      const entries = await $contentfulClient.getEntries({
        content_type: "article",
        "fields.slug": slug,
      });

      const itemJson = entries.items[0];
      if (itemJson) {
        this.item = plainToClass(Article, itemJson);
      }
    },
    async fetchCategories() {
      const { $contentfulClient } = useNuxtApp();

      const entries = await $contentfulClient.getEntries({
        content_type: "category",
      });
      if (entries.items) {
        this.categories = plainToClass(ArticleCategory, entries.items);
      }
    },
  },
});
