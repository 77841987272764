export class ArticleCategory {
  get name() {
    return this.fields.name;
  }

  get description() {
    return this.fields.description;
  }

  get slug() {
    return this.fields.slug;
  }
  private fields: {
    name: string;
    description: string;
    slug: string;
  };
}
